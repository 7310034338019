import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        <a href="#content" className="sr-only focusable">
          Direkt zum Seiteninhalt
        </a>
        <div id="top" className="page-wrapper">
        <header id="header" class="page-header ">
      <div class="page-header__wrapper">
        <a href="#" id="touch-menu" class="page-header__nav-button" data-icon="list"></a>
        <a href="/" title="Home" rel="home" class="page-header__site-logo">
          <img src="../../img/kenvue-logo-black-rgb.svg" class="page-header__site-logo-image" alt="Home"/>
        </a>
      </div>
      <nav class="main-menu">
        <ul class="main-menu__list" role="menubar">
          <li class="main-menu__item" role="none">
            <a href="/" class="main-menu__link" role="menuitem">Home</a>
          </li>
          <li class="main-menu__item active" role="none">
            <a href="/marken" class="main-menu__link" role="menuitem">Marken</a>
          </li>
          <li class="main-menu__item" role="none">
            <a href="/karriere" class="main-menu__link" role="menuitem">Karriere</a>
          </li>
          <li class="main-menu__item" role="none">
            <a href="/kontakt" class="main-menu__link" role="menuitem">Kontakt &amp; Service</a>
          </li>
          <li class="main-menu__item" role="none">
            <img class="top-employee-europe" src="../../img/Top_Employer_Europe_2022-1.jpg" />
          </li>
          <li class="main-menu__item" role="none">
            <img class="top-employee" src="../../img/Top_Employers_Austria_2022.jpg" />
          </li>
        </ul>
      </nav>
    </header>
          <main id="content" className="page-content">
            <ul className="breadcrumb">
              <li className="breadcrumb__list-item">
                <a href="/" className="breadcrumb__link">
                  Home
                </a>
              </li>
              <li className="breadcrumb__list-item">
                <span className="breadcrumb__text">&nbsp;Marken</span>
              </li>
            </ul>
            <h1 className="page-headline">
              Die starken Marken von Kenvue
            </h1>
            <div className="text-module">
              <p>
                Ein Schlüssel zum Erfolg von Kenvue ist
                unsere Innovationskraft. Das vielseitige Produktportfolio ist
                auf der ganzen Welt bekannt und erreicht nahezu jeden Haushalt
                und Verbraucher in fast allen Lebensphasen. Es umfasst unter
                anderem Gesichts-, Mund- und Körperpflegeprodukte,
                Frauenhygiene, Babypflege, Kosmetik und apothekenpflichtige
                Arzneimittel. Sicherheit, Verträglichkeit und Wirksamkeit haben
                bei uns höchste Priorität. Darum unterliegt jedes Produkt, das
                wir auf den Markt bringen, den hohen Qualitätsstandards unserer
                Experten aus Forschung und Entwicklung.
              </p>
            </div>
            <div className="brands-module">
              <ul className="brands-module__list">
                <li className="brands-module__list-item">
                  <a
                    href="https://www.bebe.de/"
                    className="brands-module__link"
                    target="_blank"
                  >
                    <img
                      src="/img/brands/bebe.png"
                      className="brands-module__image"
                      height="300"
                      width="300"
                    />
                  </a>
                </li>
                <li className="brands-module__list-item">
                  <a
                    href="https://www.bebe-zartpflege.de/"
                    className="brands-module__link"
                    target="_blank"
                  >
                    <img
                      src="/img/brands/bebe-zartpflege.png"
                      className="brands-module__image"
                      height="300"
                      width="300"
                    />
                  </a>
                </li>
                <li className="brands-module__list-item">
                  <a
                    href="https://www.carefree.de/"
                    className="brands-module__link"
                    target="_blank"
                  >
                    <img
                      src="/img/brands/carefree.png"
                      className="brands-module__image"
                      height="300"
                      width="300"
                    />
                  </a>
                </li>
                <li className="brands-module__list-item">
                  <a
                    href="http://www.hexoral.de/"
                    className="brands-module__link"
                    target="_blank"
                  >
                    <img
                      src="/img/brands/hexoral.png"
                      className="brands-module__image"
                      height="300"
                      width="300"
                    />
                  </a>
                </li>
                <li className="brands-module__list-item">
                  <a
                    href="https://www.imodium.at/"
                    className="brands-module__link"
                    target="_blank"
                  >
                    <img
                      src="/img/brands/imodium.png"
                      className="brands-module__image"
                      height="300"
                      width="300"
                    />
                  </a>
                </li>
                <li className="brands-module__list-item">
                  <a
                    href="https://www.listerine.ch/"
                    className="brands-module__link"
                    target="_blank"
                  >
                    <img
                      src="/img/brands/listerine.png"
                      className="brands-module__image"
                      height="300"
                      width="300"
                    />
                  </a>
                </li>
                <li className="brands-module__list-item">
                  <a
                    href="https://www.neutrogena.de/"
                    className="brands-module__link"
                    target="_blank"
                  >
                    <img
                      src="/img/brands/neutrogena.png"
                      className="brands-module__image"
                      height="300"
                      width="300"
                    />
                  </a>
                </li>
                <li className="brands-module__list-item">
                  <a
                    href="https://www.nicorette.at/"
                    className="brands-module__link"
                    target="_blank"
                  >
                    <img
                      src="/img/brands/nicorette.png"
                      className="brands-module__image"
                      height="300"
                      width="300"
                    />
                  </a>
                </li>
                <li className="brands-module__list-item">
                  <a
                    href="https://www.ob.de/"
                    className="brands-module__link"
                    target="_blank"
                  >
                    <img
                      src="/img/brands/o.b.png"
                      className="brands-module__image"
                      height="300"
                      width="300"
                    />
                  </a>
                </li>
                <li className="brands-module__list-item">
                  <a
                    href="https://www.penaten.de/"
                    className="brands-module__link"
                    target="_blank"
                  >
                    <img
                      src="/img/brands/penaten.png"
                      className="brands-module__image"
                      height="300"
                      width="300"
                    />
                  </a>
                </li>
                <li className="brands-module__list-item">
                  <a
                    href="https://www.pizbuin.com/"
                    className="brands-module__link"
                    target="_blank"
                  >
                    <img
                      src="/img/brands/pizbuin.png"
                      className="brands-module__image"
                      height="300"
                      width="300"
                    />
                  </a>
                </li>
                <li className="brands-module__list-item">
                  <a
                    href="https://www.regaine.de/"
                    className="brands-module__link"
                    target="_blank"
                  >
                    <img
                      src="/img/brands/regaine.png"
                      className="brands-module__image"
                      height="300"
                      width="300"
                    />
                  </a>
                </li>
              </ul>
            </div>
            <ul className="taco-module">
              <li className="taco-module__entry">
                <div
                  className="taco-module__image"
                  style={{
                    "background-image":
                      "url('../../img/taco/JnJ_Karriere_612x338.jpg')",
                    "background-position": "86% top"
                  }}
                />
                <div className="taco-module__text">
                  <h3 className="h2">
                    Jobs bei Kenvue
                  </h3>
	          <p>Wenn Sie mehr zu den Karrieremöglichkeiten bei Kenvue erfahren möchten, besuchen Sie uns auf <a href="https://www.kenvue.com/" target="_blank">www.Kenvue.com</a></p>
                </div>
              </li>
            </ul>
          </main>
          <Footer></Footer>
        </div>
        <script src="/js/main.js" />
        
        <script src="https://www.google-analytics.com/analytics.js" async />
      </div>
    );
  }
}

export default Page;
